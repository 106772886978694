@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");


:root {
  --primary: #00a94f;
  --secondary: #005daa;
  --state-warning: #be413b;
  --state-3: #3f689a;
  --state-4: #6290b7;
  --other-blue: #004783;
  --other-black: #000000;
  --other-white: #eeeeee;
}

html {
  height: 100%;
}

body {
  background-color: var(--secondary);
  font-family: "Roboto", "Open Sans", sans-serif;
  color: var(--other-white);
  height: 100%;
}

.mainLogo {
  padding: 1%;
  top: 50%;
  width: 180px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  padding: 1%;
}
form {
  width: 500px;
}
.Box {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--other-white);
  padding-left: 5%;
  color: #000000;
  max-width: 100%;
}

.dark {
  color: red;
}

.linkText {
  text-decoration: none;
  color: var(--other-white);
  font-size: clamp(0.75em, 2vw, 1em);
}

.linkText:hover {
  text-decoration: underline;
  color: rgb(198, 198, 198);
}

.button {
  background-color: var(--primary);
  padding: 5px;
  border-radius: 25px;
  width: 175px;
  color: var(--other-white);
}

.button:hover {
  background-color: rgba(0, 169, 79, 0.5);
}

.R {
  padding: 5px;
  background-color: var(--state-warning);
}
.R:hover {
  background-color: rgba(190, 65, 59, 0.5);
}

#check {
  display: inline-block;
  width: 400px;
  border: solid green;
}

.label {
  width: 100%;
  border: solid pink;
}

.container {
  padding: 5%;
}

.errMsg {
  color: red;
}

#authInfo {
  text-align: center;
}

.bg-myBG {
  background-color: var(--secondary);
}

.checkBox {
  display: inline-block;
}
.checkLabel {
  width: 95%;
  float: right;
}
.unionBox {
  background-color: var(--other-blue);
  padding: 5%;
}
.selectBox {
  color: var(--other-black);
}

.titleText {
  font-size: clamp(15px, 2vw, 50px);
}

#nav2 a {
  text-decoration: none;
  color: var(--other-white);
}
#nav2 a:hover {
  text-decoration: underline;
}

#nav2 a.active {
  color: var(--primary);
}
.nav-item {
  margin: 10px;
}

.listBox-Admin {
  width: 95%;
  border-radius: 20px;
  background-color: var(--other-blue);
  padding: 2.5%;
}

.listBox {
  border-radius: 20px;
  background-color: var(--other-blue);
  padding: 2.5%;
}

.uLbox {
  background-color: var(--other-white);
  border-radius: 15px;
  padding-bottom: 1%;
  padding-top: 2%;
}

.InfoRow {
  cursor: pointer;
}

.border {
  border: solid red;
}

.userInfotitle {
  text-decoration: underline;
}

.userInfoRow {
  color: var(--other-black);
  display: inline-block;
  margin: auto;
  overflow-y: hidden;
}

.userOpt {
  display: inline;
  margin: 2%;
}
.userInfoOpt {
  max-height: 0;
  overflow: hidden;
}
.userInfoOpt.show {
  max-height: 9999px;
  overflow: visible;
}

#collapseFunction {
  visibility: hidden;
}

.actStat {
  color: var(--primary);
}
.delStat {
  color: var(--state-warning);
}

.profBox {
  width: 1000px;
  background-color: var(--other-white);
  border-radius: 10px;
  color: var(--other-black);
  padding: 2.5%;
}

.inputBox {
  width: 100%;
  border-radius: 5px;
  padding: 1%;
}

.PWord {
  margin-bottom: 0.5%;
}

.inputSave {
  border-radius: 5px;
  background-color: var(--primary);
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.TCTitle {
  align-items: center;
}

.URcard {
  border-radius: 10px;
  background-color: var(--state-3);
  padding: 20px;
}

.AddLinkButton {
  border-radius: 10px;
  width: 80px;
  margin: 2px;
  height: 40px;
}
.AddLinkButton.save {
  background-color: var(--primary);
}
.AddLinkButton.save:hover {
  background-color: rgba(0, 169, 79, 0.5);
  color: var(--other-black);
}
.AddLinkButton.cancel {
  background-color: rgb(189, 189, 189);
}
.AddLinkButton.cancel:hover {
  background-color: rgba(189, 189, 189, 0.5);
  color: var(--other-black);
}

.toolLinkTitle {
  color: var(--other-black);
}

.textBoxArea {
  background-color: whitesmoke;
  width: 100%;
  height: 100px;
  color: black;
}

.categoryMainBox {
  display: block;
  max-width: 1000px;
  text-align: center;
}

.categoryBox {
  display: inline-block;
  width: 200px;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--other-blue);
  margin: 20px;
  text-align: center;
}

.categoryBox:hover {
  background-color: var(--state-3);
}

.categoryBox a {
  text-decoration: none;
  color: var(--other-white);
}

.previewBox {
  width: 100%;
  background-color: var(--other-white);
  color: var(--other-black);
  padding: 25px;
  text-align: left;
  border-radius: 10px;
  cursor: pointer;
}

.previewBox a {
  text-decoration: none;
  color: var(--other-black);
}

.articleBox {
  background-color: var(--other-white);
  border-radius: 10px;
  color: var(--other-black);
  width: 85%;
}

.breadcrumb a {
  color: whitesmoke;
  text-decoration: none;
}

.breadcrumb a:hover {
  color: var(--primary);
}

.ck-content {
  height: 300px;
}
.ck-content ol {
  list-style-type: decimal;
}

.ck-content ul {
  list-style-type: circle;
}

#calArea{
  background-color: whitesmoke;
  color: black;
  text-align: center;
  border-radius: 8px;
}

#addEvent-button{
  position: relative;
  width: 20%;
  background-color: var(--primary);
  border-radius: 8px;
  height: 38px;
}
.optArrow{
  position: relative;
  top: -4px;
  left: 12.5px;
}
.opt{
  display: block;
}
.opt{
  display: block;
  align-items: center;
}
.addLink{
  text-decoration: none;
  color: var(--other-white);
  position: relative;
  cursor: pointer;
  display: block;
}

.addLink:hover{
  color: var(--other-black);
  text-decoration: underline;
}

#calevent{
  border-radius: 15px;
  position: absolute;
  left: 25%;
  top: 20%;
  background-color: rgb(255, 255, 255);
  padding: 2.5%;
}

.addEvent-buttons{
  margin-top: 2.5%;
}

.filter-white{
  filter: invert(100%) sepia(16%) saturate(19%) hue-rotate(24deg) brightness(112%) contrast(87%);
}

.filter-white:hover{
  filter: invert(39%) sepia(93%) saturate(754%) hue-rotate(109deg) brightness(97%) contrast(101%);
}

.filter-green{
  filter: invert(39%) sepia(93%) saturate(754%) hue-rotate(109deg) brightness(97%) contrast(101%);
}

.eventForm{
  width: auto;
}

.modal {
  font-family: 'Patrick Hand', cursive !important;
}

.optArrowUser{
  position: relative;
  top: 30%;
}

.myP{
  margin-bottom: 20px;
  width: 97%;
}

.eventCard{
  background-color: var(--other-white);
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  width: 205px;
}

.eventCardTitle{
  color: var(--primary);
}

.eventCardDate{
  color: var(--other-black);
}

.eventCardDescription{
  color: var(--other-black);
}

.eventCardDescription-blank{
  color: Darkgray;
}

.eventCardCenter{
  overflow-x: auto;
}

.articleSection{
  width: 100%;
}

.articleCard{
  padding: 1%;
  margin: 5px;
  color: black;
}

.change-svg{
  filter: brightness(0) saturate(100%)
}

.noTags[data-placeholder]:not([data-placeholder=""]):empty::before {
  content: attr(data-placeholder);
  color: gray;
}