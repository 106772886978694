.user-form-logo-bg {
    max-height: 110px;
    background-color: #3F689A;
}

.user-form-logo-bg-round {
    border-radius: 300px;
    height: 149px;
    max-width: 900px;
    margin: auto;
    background-color: #3F689A;
}

.user-form-logo {
    position: static;
    z-index: 2;
    height: auto;
    max-width: 347px;
    width:100%;
    top: 19px;
    border-radius: 0px;
    margin:auto;
    padding: 30px;
}

.form-title{
    margin-top: 150px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 45px;
    align-items: center;
    text-align: center;
    
    color: #00A94F;
    text-align: center;
}
.login-form-entry {
width: 749px;
height: 99px;
top: 432px;

background: #FFFFFF;
background-blend-mode: multiply;
mix-blend-mode: normal;
border-radius: 300px;
}