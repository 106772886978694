.modalBackground {
  width: 100vw;
  height: 100vh;
  
  margin-right:auto;
  margin-left:0%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  text-align: center;
  printer-events: auto;
}

.modalContainer {
  width: 85vw;
  height: 110vh;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

@media screen and (max-width: 1200px) { 
    .modalBackground {
        width: 100vw;
        height: 100vh;
        background-color: white;
        margin-right:auto;
        margin-left:0%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 10;
        text-align: center;
        printer-events: auto;
    }

    .modalContainer {
        width: 95vw;
        height: 90vh;
        border-radius: 12px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        display: flex;
        flex-direction: column;
        padding: 25px;
    }
    
    .modalContainer .body {
        align-items: center;
        font-size: 1rem;
        text-align: left;
        margin-top: 2px;
        margin-left: 0px;
        padding-left:5px;
        margin-bottom:0px;
        display: inline-block;
    }    
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
  color: black;
  fontWeight: 800;
  fontSize: 35;
  top: 0px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
        
.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

.disableDivContent {
    pointer-events: none;
    opacity: 0.4;
}

.is-disabled
{
  pointer-events: none;
  
}

#cancelBtn {
  background-color: crimson;
}    
