.login-form-title{
    height: 43px;
    top: 336px;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 25px;
    align-items: center;
    text-align: center;
    
    color: #FFF;
    text-align: center;
}
.login-form-entry {
width: 749px;
height: 99px;
top: 432px;

background: #FFFFFF;
background-blend-mode: multiply;
mix-blend-mode: normal;
border-radius: 300px;
}
